export const purple = "#55284b";
export const pink = "#ff0064";
export const white = "#ffffff";
export const gray = "#f9f9f9";
export const black = "#000";
export const blue = "blue";
export const none = "transparent";

export const text = black;
export const primary = black;
export const accent = pink;

export const colors = {
  purple,
  pink,
  white,
  gray,
  black,
  blue,
  none,
  text,
  primary,
  accent
};
