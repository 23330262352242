import {
  Group,
  WebGLRenderer,
  LoadingManager,
  TextureLoader,
  CubeTextureLoader,
  Raycaster,
  Scene,
  Mesh,
  MeshDepthMaterial,
  MeshBasicMaterial,
  MeshStandardMaterial,
  MultiMaterial,
  Spherical,
  RGBADepthPacking,
  sRGBEncoding,
  RGBFormat,
  Uncharted2ToneMapping,
  PCFSoftShadowMap,
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  SpotLight,
  Vector2,
  Vector3,
  DoubleSide,
  FrontSide,
  Texture,
  Object3D
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as TWEEN from "@tweenjs/tween.js";
import { DRACOLoader } from "./DRACOLoader";

type Fragment = "about" | "music" | "specials" | "studio" | "team" | "work";

type Fragments = Fragment[];

interface StateInterface {
  isFirstStart: boolean;
  isReady: boolean;
  isIdle: boolean;
  isRotating: boolean;
  isDragging: boolean;
  hoveredFragment?: string;
  pointerDownTime: number;
  rotationVelocity: number;
}

interface ConfigInterface {
  modelPath: string;
  dracoPath: string;
  mainTexture: string;
  cubeMaps: [string, string, string, string, string, string];
  fragments: Fragments;
  enterFromPos: { [fragment: string]: number };
  dampingFactor: number;
  epsilon: number;
  speedFactor: number;
  maxClickDuration: number;
  maxClickDiff: number;
}

export class Model {
  protected group: Group;
  protected camera: PerspectiveCamera;
  protected scene: Scene;
  protected renderer: WebGLRenderer;
  protected raycaster: Raycaster;
  protected eyeLeft: Object3D;
  protected eyeLeftPosition = new Vector3();
  protected eyeRight: Object3D;
  protected eyeRightPosition = new Vector3();
  protected mousePosition = new Vector2();
  protected worldMousePosition = new Vector3();
  protected lastMousePosition = new Vector2();
  protected idleTimer: any;
  protected cameraResetTimer: any;
  protected fragments: { [name: string]: Object3D } = {};
  protected raycastTargets: { [name: string]: Object3D } = {};
  protected tweens: { [name: string]: TWEEN.Tween } = {};
  protected offset = new Vector2();
  protected size = new Vector2();
  protected cameraPosSpherical = new Spherical();

  protected dragStartPosition = new Vector2();
  protected v2 = new Vector2();

  protected enterFrom?: string;
  protected onClick?: (name: string) => void;
  protected onReady?: () => void;

  protected initialize: Promise<void>;

  protected state: StateInterface = {
    isFirstStart: true,
    isReady: false,
    isIdle: false,
    isRotating: false,
    isDragging: false,
    hoveredFragment: undefined,
    pointerDownTime: -1,
    rotationVelocity: 0.0
  };

  protected config: ConfigInterface = {
    modelPath: "/skull.draco.glb",
    dracoPath: "/draco/",
    mainTexture: "/texture-main.png",
    cubeMaps: [
      "/pos-x.jpg",
      "/neg-x.jpg",
      "/pos-y.jpg",
      "/neg-y.jpg",
      "/pos-z.jpg",
      "/neg-z.jpg"
    ],
    fragments: ["about", "music", "specials", "studio", "team", "work"],
    enterFromPos: {
      about: 2.5,
      music: 1,
      specials: 0,
      studio: 5,
      team: 3.75,
      work: 0
    },
    dampingFactor: 0.85,
    epsilon: 0.0005,
    speedFactor: 2,
    maxClickDuration: 300,
    maxClickDiff: 0.075
  };

  constructor(
    enterFrom?: string,
    onReady?: () => void,
    onClick?: (fragment: string) => void
  ) {
    this.enterFrom = enterFrom;
    this.onClick = onClick;
    this.onReady = onReady;
    this.group = new Group();
    this.scene = new Scene();
    this.raycaster = new Raycaster();

    // setup renderer
    this.renderer = new WebGLRenderer({
      antialias: true,
      alpha: true
    });

    // setup camera
    this.camera = new PerspectiveCamera(
      60,
      this.size.x / this.size.y,
      0.01,
      1000
    );

    this.initialize = new Promise((resolve, reject) => {
      this.loadAssets()
        .then(([skull, texture, cubemap, fragments]) => {
          this.group.add(skull);

          // start generating the main-texture
          const completeSkull = this.group.getObjectByName("combined");

          if (!completeSkull) {
            throw new Error("Could not load skull.");
          }

          completeSkull.material.map = texture;
          completeSkull.material.side = FrontSide;
          completeSkull.material.fog = FrontSide;
          completeSkull.material.envMap = cubemap;
          // completeSkull.material.envMapIntensity = 0.5;
          completeSkull.material.metalness = 0.1;
          completeSkull.material.roughness = 0.1;

          // init fragments
          const fragmentMaterial = new MeshStandardMaterial();
          fragmentMaterial.side = DoubleSide;
          fragmentMaterial.fog = false;
          fragmentMaterial.transparent = true;
          fragmentMaterial.metalness = 0;
          fragmentMaterial.roughness = 0;

          const depthMaterial = new MeshDepthMaterial({
            depthPacking: RGBADepthPacking,
            alphaTest: 0.5
          });

          const rctMaterial = new MeshBasicMaterial();
          rctMaterial.visible = false;

          this.group.traverseVisible((obj: any) => {
            if (obj.name.startsWith("fragment-")) {
              const name = obj.name.slice("fragment-".length);
              // @ts-ignore
              const texture = fragments.find(
                (fragment: any) => fragment.name === name
              );

              obj.material = fragmentMaterial.clone();
              obj.material.map = texture;

              // custom-depth-material is needed to make shadows
              // for the mostly transparent meshes work properly
              obj.customDepthMaterial = depthMaterial.clone();
              obj.castShadow = true;

              obj.scale.set(1.001, 1.001, 1.001); // prevent z-fighting
              obj.scaleTween = new TWEEN.Tween(obj.scale);

              // as skull-fragments for navigation are moved/scaled on hover,
              // the raycasting needs to work with static duplicates of these
              // meshes. Those are created here
              const raycastTarget = new Mesh(obj.geometry, rctMaterial);

              raycastTarget.name = obj.name;

              if (obj.name === "fragment-specials") {
                raycastTarget.scale.set(1.1, 1.1, 0.925);
              } else {
                raycastTarget.scale.set(1.001, 1.001, 1.001);
              }

              skull.add(raycastTarget);

              this.raycastTargets[obj.name] = raycastTarget;

              // @ts-ignore
              this.fragments[obj.name] = obj;
            }

            depthMaterial.dispose();
            fragmentMaterial.dispose();
            rctMaterial.dispose();

            // @ts-ignore
            if (obj.material) {
              obj.castShadow = true;
              obj.receiveShadow = true;
            }
          });

          this.group.castShadow = true;
          this.group.receiveShadow = true;

          this.eyeLeft = this.group.getObjectByName("eye-ref-left");
          this.eyeRight = this.group.getObjectByName("eye-ref-right");

          this.eyeLeftPosition = this.eyeLeft.position.clone();
          this.eyeRightPosition = this.eyeRight.position.clone();

          // setup lightning
          const ambLight = new AmbientLight(0xffffff, 0.39);
          const dirLight = new DirectionalLight(0xffffff, 0.2);

          Object.assign(dirLight.shadow.camera, {
            left: -2.5,
            right: 2.5,
            top: 2.5,
            bottom: -2.5,
            near: 3,
            far: 9.5
          });

          const spotLight = new SpotLight(0xffffff, 0.2);
          // spotLight.position.set(0, 50, 100);
          spotLight.target = this.group;
          // spotLight.distance = 400;

          this.camera.position.set(0, 2.5, 40);
          this.camera.lookAt(this.group.position);
          this.camera.add(spotLight);

          // setup scene
          this.scene.add(this.camera);
          this.scene.add(dirLight, ambLight);
          this.scene.add(this.group);

          // setup raycaster
          this.raycaster = new Raycaster();
          this.raycaster.linePrecision = 5;

          this.renderer.toneMapping = Uncharted2ToneMapping;
          // this.renderer.toneMappingExposure = 1.0;
          this.renderer.shadowMap.enabled = true;
          this.renderer.shadowMap.type = PCFSoftShadowMap;
          this.renderer.gammaOutput = true;
          this.renderer.gammaInput = true;
          this.renderer.gammaFactor = 2.2;
          this.renderer.setClearColor(0xffffff, 0);
          this.renderer.setPixelRatio(1); // window.devicePixelRatio);

          resolve();
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  }

  public start() {
    this.initialize.then(() => {
      this.state = {
        isFirstStart: this.state.isFirstStart,
        isIdle: false,
        isReady: false,
        isDragging: false,
        isRotating: false,
        hoveredFragment: undefined,
        pointerDownTime: -1,
        rotationVelocity: 0.0
      };

      // setup container
      const container = document.getElementById("skull");

      if (!container) {
        throw new Error("Could not find container to render canvas.");
      }

      container.appendChild(this.renderer.domElement);

      this.onWindowResize();

      // add event-handlers
      window.addEventListener("resize", this.onWindowResize, {
        passive: true
      });
      window.addEventListener("mousemove", this.onMouseMove, {
        passive: true
      });
      window.addEventListener("mousedown", this.onMouseDown, {
        passive: true
      });
      window.addEventListener("mouseup", this.onMouseUp, {
        passive: true
      });
      this.renderer.domElement.addEventListener(
        "touchstart",
        this.onMouseDown,
        {
          passive: true
        }
      );
      this.renderer.domElement.addEventListener("touchmove", this.onMouseMove, {
        passive: true
      });
      this.renderer.domElement.addEventListener("touchend", this.onMouseUp, {
        passive: true
      });

      this.tweens.cameraPosition = new TWEEN.Tween(this.cameraPosSpherical)
        .easing(TWEEN.Easing.Back.Out)
        .onUpdate(() => {
          if (this.camera) {
            this.camera.position.setFromSpherical(this.cameraPosSpherical);
            this.camera.lookAt(this.group.position);
          }
        });

      this.tweens.skullHover = new TWEEN.Tween(this.group.position);
      this.tweens.skullRotateRight = new TWEEN.Tween(this.group.rotation);
      this.tweens.skullRotateLeft = new TWEEN.Tween(this.group.rotation);

      this.renderer.setAnimationLoop(this.renderloop);

      if (this.state.isFirstStart) {
        // Intro animation
        this.group.position.set(0, 0, -500);
        this.group.rotation.z = -90;

        new TWEEN.Tween(this.group.position)
          .easing(TWEEN.Easing.Exponential.Out)
          .to({ x: 0, y: 0, z: 0 }, 2000)
          .start();

        new TWEEN.Tween(this.group.rotation)
          .easing(TWEEN.Easing.Exponential.Out)
          .to(
            {
              x: 0,
              y:
                this.enterFrom && this.config.enterFromPos[this.enterFrom]
                  ? -this.config.enterFromPos[this.enterFrom]
                  : 0,
              z: 0
            },
            2000
          )
          .onComplete(this.onIntroReady)
          .start();

        this.state.isFirstStart = false;
      } else {
        new TWEEN.Tween(this.group.rotation)
          .easing(TWEEN.Easing.Exponential.Out)
          .to(
            {
              x: 0,
              y:
                this.enterFrom && this.config.enterFromPos[this.enterFrom]
                  ? -this.config.enterFromPos[this.enterFrom]
                  : 0,
              z: 0
            },
            1000
          )
          .onComplete(this.onIntroReady)
          .start();
      }
    });
  }

  public stop() {
    this.stopIdleAnimation();

    Object.keys(this.fragments).forEach(name => {
      this.fragments[name].scale.set(1.001, 1.001, 1.001);
    });

    // remove tweens
    TWEEN.removeAll();

    if (this.idleTimer) {
      clearTimeout(this.idleTimer);
    }

    if (this.cameraResetTimer) {
      clearTimeout(this.cameraResetTimer);
    }

    window.removeEventListener("resize", this.onWindowResize, {
      passive: true
    });
    window.removeEventListener("mousemove", this.onMouseMove, {
      passive: true
    });
    window.removeEventListener("mousedown", this.onMouseDown, {
      passive: true
    });
    window.removeEventListener("mouseup", this.onMouseUp, {
      passive: true
    });

    if (this.renderer) {
      if (this.renderer.domElement) {
        this.renderer.domElement.removeEventListener(
          "touchstart",
          this.onMouseDown,
          {
            passive: true
          }
        );
        this.renderer.domElement.removeEventListener(
          "touchmove",
          this.onMouseMove,
          {
            passive: true
          }
        );
        this.renderer.domElement.removeEventListener(
          "touchend",
          this.onMouseUp,
          {
            passive: true
          }
        );
      }

      this.renderer.setAnimationLoop(null);
    }
  }

  public setEnterFrom(enterFrom: string | undefined) {
    this.enterFrom = enterFrom;
  }

  protected renderloop = () => {
    this.group.rotation.y += this.state.rotationVelocity;
    this.group.rotation.y %= Math.PI * 2;
    this.state.rotationVelocity *= this.config.dampingFactor;

    if (
      Math.abs(this.state.rotationVelocity) < this.config.epsilon &&
      !this.state.isDragging &&
      this.state.isRotating
    ) {
      this.state.isRotating = false;

      this.state.rotationVelocity = 0;
      this.startIdleTimer(1500);
    }

    TWEEN.update();
    this.renderer.render(this.scene, this.camera);
  };

  protected onIntroReady = () => {
    this.state.isReady = true;

    this.startIdleTimer(2500);

    if (typeof this.onReady === "function") {
      this.onReady();
    }
  };

  protected onWindowResize = () => {
    const container = document.getElementById("skull");
    const rect = container.getBoundingClientRect();

    this.renderer.setSize(rect.width, rect.height);
    this.camera.aspect = rect.width / rect.height;
    this.camera.updateProjectionMatrix();

    let top = 0;
    let left = 0;

    let n = this.renderer.domElement;

    do {
      top += n.offsetTop;
      left += n.offsetLeft;
      // @ts-ignore
    } while ((n = n.offsetParent));

    this.offset.set(left, -top);
    this.size.set(rect.width, rect.height);
  };

  protected onMouseMove = (e: MouseEvent | Touch) => {
    let event;
    let isTouch = false;

    if (e.touches && e.touches[0]) {
      event = e.touches[0];
      isTouch = true;
    } else {
      event = e;
    }

    this.updateMousePosition(event.pageX, event.pageY);
    this.updateWorldMousePosition();

    if (
      this.state.isDragging &&
      !(
        isTouch &&
        Math.abs(this.mousePosition.y - this.lastMousePosition.y) > 0.075
      )
    ) {
      this.v2.copy(this.mousePosition).sub(this.lastMousePosition);

      this.state.rotationVelocity = this.config.speedFactor * this.v2.x;
      this.lastMousePosition.copy(this.mousePosition);
      this.state.isRotating = true;

      this.cameraPosSpherical.setFromVector3(this.camera.position);
      this.cameraPosSpherical.phi =
        Math.PI / 2 +
        ((this.mousePosition.y - this.dragStartPosition.y) * Math.PI) / 4;
      this.camera.position.setFromSpherical(this.cameraPosSpherical);
      this.camera.lookAt(this.group.position);
    } else {
      this.v2.copy(this.mousePosition).sub(this.lastMousePosition);
      this.lastMousePosition.copy(this.mousePosition);
      this.state.isRotating = false;
      this.state.isDragging = false;
    }

    const mousePosition = this.worldMousePosition.clone();

    // update mouse-position
    mousePosition.x = mousePosition.x * 2;
    mousePosition.z = -mousePosition.y * 2;
    mousePosition.y = -1;

    this.eyeLeft.position
      .set(
        this.eyeLeftPosition.x,
        this.eyeLeftPosition.y,
        this.eyeLeftPosition.z
      )
      .add(mousePosition);

    this.eyeRight.position
      .set(
        this.eyeRightPosition.x,
        this.eyeRightPosition.y,
        this.eyeRightPosition.z
      )
      .add(mousePosition);

    // update raycaster intersects
    if (this.raycaster) {
      // update the picking ray with the camera and mouse position
      this.raycaster.setFromCamera(this.mousePosition, this.camera);

      const [intersect] = this.raycaster.intersectObjects(
        Object.values(this.raycastTargets)
      );

      if (intersect) {
        if (this.state.hoveredFragment !== intersect.object.name) {
          if (this.state.hoveredFragment) {
            this.deactivateFragment(this.state.hoveredFragment);
          }

          this.state.hoveredFragment = intersect.object.name;
          this.activateFragment(intersect.object.name);
        }
      } else {
        if (this.state.hoveredFragment) {
          this.deactivateFragment(this.state.hoveredFragment);
        }
        this.state.hoveredFragment = undefined;
      }
    }
  };

  protected onMouseDown = (e: MouseEvent | Touch) => {
    let event;

    if (e.touches && e.touches[0]) {
      event = e.touches[0];
    } else {
      event = e;
    }

    this.enterFrom = undefined;
    this.updateMousePosition(event.pageX, event.pageY);

    this.state.isDragging = true;
    this.state.pointerDownTime = Date.now();

    this.cameraPosSpherical.setFromVector3(this.camera.position);

    clearTimeout(this.cameraResetTimer);

    this.tweens.cameraPosition.stop();
    this.lastMousePosition.copy(this.mousePosition);
    this.dragStartPosition.copy(this.mousePosition);

    this.stopIdleAnimation();
  };

  protected onMouseUp = (e: MouseEvent | Touch) => {
    let event;

    if (e.touches && e.touches[0]) {
      event = e.touches[0];
    } else {
      event = e;
    }

    this.updateMousePosition(event.pageX, event.pageY);

    if (this.state.isDragging) {
      this.state.isDragging = false;

      this.cameraResetTimer = setTimeout(() => {
        this.tweens.cameraPosition
          .stop()
          .to({ phi: Math.PI / 2 }, 2000)
          .start();
      }, 500);
    }

    const dx = this.dragStartPosition.x - this.mousePosition.x;
    const dy = this.dragStartPosition.y - this.mousePosition.y;
    const diff = Math.sqrt(dx * dx + dy * dy);

    if (
      Date.now() - this.state.pointerDownTime < this.config.maxClickDuration &&
      diff < this.config.maxClickDiff &&
      this.state.hoveredFragment
    ) {
      if (typeof this.onClick === "function") {
        if (event.target.id !== "calltoaction") {
          this.onClick(this.state.hoveredFragment);
        }
      }
    }
  };

  protected updateMousePosition = (x: number, y: number) => {
    this.mousePosition.set(
      (2 * (x - this.offset.x)) / this.size.x - 1,
      (2 * (this.size.y - y - this.offset.y)) / this.size.y - 1
    );
  };

  protected updateWorldMousePosition = () => {
    this.worldMousePosition = new Vector3(
      this.mousePosition.x,
      this.mousePosition.y,
      0.5
    )
      .unproject(this.camera)
      .sub(this.camera.position)
      .normalize();
  };

  protected activateFragment(id: string) {
    this.renderer.domElement.style.cursor = "pointer";

    const hoverScale =
      id === "fragment-specials"
        ? { x: 1.02, y: 1.02, z: 1.02 }
        : { x: 1.1, y: 1.1, z: 1.1 };

    // @ts-ignore
    this.fragments[id].scaleTween
      .stop()
      .easing(TWEEN.Easing.Quadratic.Out)
      .to(hoverScale, 200)
      .start();
  }

  protected deactivateFragment = (id: string) => {
    this.renderer.domElement.style.cursor = "";

    // @ts-ignore
    this.fragments[id].scaleTween
      .stop()
      .easing(TWEEN.Easing.Quadratic.Out)
      .to({ x: 1.001, y: 1.001, z: 1.001 }, 500)
      .start();
  };

  protected startIdleTimer(timeout = 500) {
    this.idleTimer = setTimeout(() => {
      this.idleAnimation();
    }, timeout);
  }

  protected idleAnimation() {
    if (!this.state.isIdle) {
      this.state.isIdle = true;

      this.tweens.cameraPosition.stop();

      this.tweens.skullHover
        .stop()
        .easing(TWEEN.Easing.Quadratic.InOut)
        .to({ y: -1 }, 1000)
        .repeat(Infinity)
        .yoyo(true)
        .start();

      if (!this.enterFrom || this.enterFrom.length === 0) {
        this.tweens.skullRotateRight
          .stop()
          .easing(TWEEN.Easing.Quadratic.InOut)
          .to({ y: -Math.PI / 8 }, 3000)
          .chain(this.tweens.skullRotateLeft)
          .delay(500)
          .start();

        this.tweens.skullRotateLeft
          .stop()
          .easing(TWEEN.Easing.Quadratic.InOut)
          .to({ y: Math.PI / 8 }, 3000)
          .delay(500)
          .chain(this.tweens.skullRotateRight);
      }
    }
  }

  protected stopIdleAnimation() {
    clearTimeout(this.idleTimer);

    if (this.state.isIdle) {
      this.state.isIdle = false;

      this.tweens.skullHover
        .stop()
        .easing(TWEEN.Easing.Quadratic.Out)
        .to({ y: 0 }, 500)
        .repeat(0)
        .start();

      if (
        this.tweens.skullRotateRight &&
        (!this.enterFrom || this.enterFrom.length === 0)
      ) {
        this.tweens.skullRotateRight.stop();
      }
    }
  }

  protected loadAssets(): Promise<[Object3D, Texture, Texture, Texture[]]> {
    const manager = new LoadingManager();
    const promises = [];

    promises.push(
      new Promise((resolve, reject) => {
        const gltfLoader = new GLTFLoader(manager);

        DRACOLoader.setDecoderConfig({});
        DRACOLoader.setDecoderPath(this.config.dracoPath);
        DRACOLoader.getDecoderModule();

        gltfLoader.setDRACOLoader(new DRACOLoader(manager));

        gltfLoader.load(
          this.config.modelPath,
          gltfData => {
            const skull = gltfData.scene.getObjectByName("skull");

            // Release decoder resources.
            DRACOLoader.releaseDecoderModule();
            gltfData.scene.dispose();

            resolve(skull);
          },
          undefined,
          reject
        );
      })
    );

    promises.push(
      new Promise((resolve, reject) => {
        const textureLoader = new TextureLoader(manager);

        textureLoader.load(
          this.config.mainTexture,
          texture => {
            texture.flipY = false;
            texture.encoding = sRGBEncoding;
            texture.name = "main";

            resolve(texture);
          },
          undefined,
          reject
        );
      })
    );

    promises.push(
      new Promise((resolve, reject) => {
        const cubeLoader = new CubeTextureLoader(manager);

        cubeLoader.load(
          this.config.cubeMaps,
          cubemap => {
            cubemap.format = RGBFormat;

            resolve(cubemap);
          },
          undefined,
          reject
        );
      })
    );

    promises.push(
      Promise.all(
        this.config.fragments.map(fragment => {
          return new Promise((resolve, reject) => {
            const textureLoader = new TextureLoader(manager);

            textureLoader.load(
              `/texture-fragment-${fragment}.svg`,
              texture => {
                texture.flipY = false;
                texture.encoding = sRGBEncoding;
                texture.name = fragment;

                resolve(texture);
              },
              undefined,
              reject
            );
          });
        })
      )
    );

    // @ts-ignore
    return Promise.all(promises);
  }
}

export function disposeMaterial(mtrl: MeshStandardMaterial) {
  if (mtrl.map) {
    mtrl.map.dispose();
  }

  if (mtrl.lightMap) {
    mtrl.lightMap.dispose();
  }

  if (mtrl.bumpMap) {
    mtrl.bumpMap.dispose();
  }

  if (mtrl.normalMap) {
    mtrl.normalMap.dispose();
  }

  // @ts-ignore
  if (mtrl.specularMap) {
    // @ts-ignore
    mtrl.specularMap.dispose();
  }

  if (mtrl.envMap) {
    mtrl.envMap.dispose();
  }

  mtrl.dispose();
}

export function disposeNode(node: any) {
  if (
    node.children &&
    Array.isArray(node.children) &&
    node.children.length > 0 &&
    !node.parent
  ) {
    node.traverse((child: any) => {
      if (child.uuid !== node.uuid) {
        disposeNode(child);
      }
    });
  }

  if (node.geometry) {
    node.geometry.dispose();
  }

  if (node.texture) {
    node.texture.dispose();
  }

  if (node.mesh) {
    node.mesh.dispose();
  }

  if (node.material) {
    let materialArray;

    if (node.material instanceof MultiMaterial) {
      // @ts-ignore
      materialArray = node.material.materials;
    } else if (node.material instanceof Array) {
      materialArray = node.material;
    }

    if (materialArray) {
      materialArray.forEach(disposeMaterial);
    } else {
      disposeMaterial(node.material);
    }
  }

  if (node.customDepthMaterial) {
    disposeMaterial(node.customDepthMaterial);
  }

  // @ts-ignore
  if (typeof node.dispose === "function") {
    // @ts-ignore
    node.dispose();
  }
}
