export const smoothScrollToAnchor = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  if (typeof window !== "undefined") {
    event.preventDefault();

    const anchor = document.getElementById(
      event.currentTarget.href.split("#")[1]
    );

    if (anchor) {
      event.preventDefault();

      anchor.scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
