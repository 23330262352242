export const fontSizes = [15, 16, 20, 24, 32, 40, 56, 72];

export const light = 300;
export const semiLight = 350;
export const normal = 400;
export const medium = 520;
export const bold = 700;
export const heavy = 900;

// alias
export const regular = normal;

// styled-system's `fontWeight` function can hook into the `fontWeights` object
export const fontWeights = {
  light,
  semiLight,
  normal,
  medium,
  bold,
  heavy
};

export const lineHeights = {
  standard: 1.5,
  display: 1.25
};

export const letterSpacings = {
  normal: "normal",
  caps: "0.025em"
};

export const fonts = {
  vario: `SuperVario`,
  nittiGrotesk: `NittiGrotesk, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  nittiGroteskSemiLight: `NittiGrotesk-SemiLight, sans-serif`,
  nittiGroteskBold: `NittiGrotesk-Bold, sans-serif`,
  nittiGroteskHeavy: `NittiGrotesk-Heavy, sans-serif`
};

export const baseFont = fonts.nittiGrotesk;
export const baseFontSize = fontSizes[0];
export const baseLineHeight = lineHeights.standard;
