import React, { useCallback } from "react";
import posed from "react-pose";
import { styled, media } from "../../styles";
import callToActionImg from "./assets/call-to-action.png";

export const CallToAction: React.FC<{
  isVisible: boolean;
  onClick: () => void;
}> = ({ isVisible, onClick }) => {
  const hideCta = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  }, []);

  return (
    <CallToActionImage
      src={callToActionImg}
      id="calltoaction"
      onClick={hideCta}
      initialPose="exit"
      pose={isVisible ? "enter" : "exit"}
    />
  );
};

const CallToActionImage = styled(
  posed.img({
    enter: { y: "0%", rotate: "-20deg" },
    exit: { y: "-300%", rotate: "0deg" }
  })
)`
  position: absolute;
  z-index: 11;
  user-select: none;
  width: 45vmin;
  max-width: 150px;
  margin-left: -20vmin;
  margin-top: -50vmin;
  cursor: pointer;

  ${media.landscape} {
    margin-top: -20vmin;
  }

  ${media.tablet} {
    max-width: 300px;
    margin-top: -20vmin;
  }

  ${media.laptop} {
    max-width: 300px;
    margin-top: -20vmin;
  }

  ${media.desktop} {
    margin-top: -25vmin;
    margin-left: -20vmin;
  }
`;
