import React, {
  FC,
  Dispatch,
  createContext,
  useReducer,
  useEffect
} from "react";
import fetch from "isomorphic-unfetch";
import { ProjectInterface, ProjectCategoryInterface } from "../ProjectPage";

const filterProjects = (
  projects: ProjectInterface[],
  selectedCategory: string | undefined = undefined,
  searchTerm: string | undefined = undefined
): ProjectInterface[] => {
  if (!searchTerm && !selectedCategory) {
    return projects;
  }

  const filteredProjects = projects.filter(project => {
    if (!searchTerm && selectedCategory) {
      if (
        !project.categories.find(category => category.slug === selectedCategory)
      ) {
        return false;
      }
    }

    if (!searchTerm) {
      return true;
    }

    if (
      project.facts &&
      project.facts.find(fact =>
        fact.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
      )
    ) {
      return true;
    }

    if (
      project.tags &&
      project.tags.find(tag =>
        tag.toLowerCase().includes(searchTerm.toLowerCase().trim())
      )
    ) {
      return true;
    }

    return project.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase().trim());
  });

  if (selectedCategory) {
    filteredProjects.sort((a, b) => {
      const aCat = a.categories.find(
        category => category.slug === selectedCategory
      );
      const bCat = b.categories.find(
        category => category.slug === selectedCategory
      );

      if (!aCat || !bCat) {
        return 0;
      }

      return aCat.idx - bCat.idx;
    });
  }

  return filteredProjects;
};

const initializeCache = async (): Promise<{
  projects: ProjectInterface[];
  categories: ProjectCategoryInterface[];
}> =>
  new Promise(resolve => {
    let projects = [];
    let categories = [];


    // try {
    //   if (typeof sessionStorage !== "undefined") {
    //     const projectsJson = sessionStorage.getItem("@@gw|projects");
    //     const categoriesJson = sessionStorage.getItem("@@gw|categories");

    //     if (projectsJson && categoriesJson) {
    //       projects = JSON.parse(projectsJson);
    //       categories = JSON.parse(categoriesJson);

    //       resolve({ projects, categories });
    //     }
    //   }
    // } catch (e) {
    //   console.error("Error loading projects:", e);

    //   projects = [];
    //   categories = [];
    // }

    if (!projects.length || !categories.length) {
      fetch("/data/youtube.json")
        .then(r => {
          // console.log(r.headers.get("Last-Modified"));
          // console.log('r.json()')

           return r
            .json()
            .then(
              (json: {
                projects: ProjectInterface[];
                categories: ProjectCategoryInterface[];
              }) => {
                return {
                  json,
                  lastModified:
                    r.headers.get("Last-Modified") || Date.now().toString()
                };
              }
            );
        })
        .then(
          (data: {
            json: {
              projects: ProjectInterface[];
              categories: ProjectCategoryInterface[];
            };
            lastModified: string;
          }) => {
            if (typeof sessionStorage !== "undefined") {
              sessionStorage.setItem(
                "@@gw|lastModified",
                JSON.stringify(data.lastModified)
              );
              sessionStorage.setItem(
                "@@gw|projects",
                JSON.stringify(data.json.projects)
              );
              sessionStorage.setItem(
                "@@gw|categories",
                JSON.stringify(data.json.categories)
              );
            }

            resolve(data.json);
          }
        );
    }
  });

interface scrollMeta {
  items: number;
  position: number;
}

export interface State {
  selectedCategory: string;
  searchTerm: string;
  searchOpen: boolean;
  projects: ProjectInterface[];
  filteredProjects: ProjectInterface[];
  categories: ProjectCategoryInterface[];
  initialized: boolean;
  scrollMeta: scrollMeta;
}

export type Action =
  | { type: "SET_CATEGORY"; selectedCategory: string }
  | { type: "SET_SEARCHTERM"; searchTerm: string }
  | { type: "TOGGLE_SEARCH"; searchOpen?: boolean }
  | { type: "CLOSE_SEARCH" }
  | { type: "SET_SCROLL_POS"; meta: scrollMeta }
  | {
      type: "INITIALIZE";
      projects: ProjectInterface[];
      categories: ProjectCategoryInterface[];
    };

const initialState: State = {
  selectedCategory: "",
  searchTerm: "",
  searchOpen: true,
  projects: [],
  filteredProjects: [],
  categories: [],
  initialized: false,
  scrollMeta: { items: 10, position: 0 }
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_CATEGORY": {
      return {
        ...state,
        filteredProjects: filterProjects(
          state.projects,
          action.selectedCategory,
          state.searchTerm
        ),
        selectedCategory: action.selectedCategory,
        scrollMeta: { items: 10, position: 0 }
      };
    }

    case "SET_SEARCHTERM": {
      return {
        ...state,
        filteredProjects: filterProjects(
          state.projects,
          state.selectedCategory,
          action.searchTerm
        ),
        scrollMeta: { items: 10, position: 0 },
        searchTerm: action.searchTerm,
        searchOpen:
          action.searchTerm === "" && state.searchTerm !== ""
            ? false
            : state.searchOpen
      };
    }

    case "TOGGLE_SEARCH": {
      return {
        ...state,
        searchTerm: "",
        searchOpen:
          action.searchOpen !== undefined
            ? action.searchOpen
            : !state.searchOpen
      };
    }

    case "SET_SCROLL_POS": {
      return {
        ...state,
        scrollMeta: { items: action.meta.items, position: action.meta.position }
      };
    }

    case "CLOSE_SEARCH": {
      return {
        ...state,
        searchTerm: "",
        searchOpen: false
      };
    }

    case "INITIALIZE": {
      // console.log({state})
      return {
        ...state,
        initialized: true,
        projects: action.projects,
        categories: action.categories,
        filteredProjects: filterProjects(
          action.projects,
          state.selectedCategory,
          state.searchTerm
        )
      };
    }

    default: {
      throw new Error("Unknown action.");
    }
  }
};

export const WorkContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => initialState
});

export const WorkContextProvider: FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initializeCache().then(({ projects, categories }) => {
      // console.log({projects})
      dispatch({
        type: "INITIALIZE",
        projects,
        categories
      });
    });
  }, []);

  return (
    <WorkContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkContext.Provider>
  );
};
