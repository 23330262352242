import { setupI18n } from "@lingui/core";
import { siteMetadata } from "../gatsby-config";

export type Locale = "DE" | "EN";

export interface Locales {
  [locale: string]: string;
}

export const locales: Locales = {
  de: "Deutsch",
  en: "English"
};

export const defaultLocale = "de";

// const loadCatalog = async (locale: Locale) => {
//   return import(/* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
//   `./locales/${locale}/messages.js`);

//   // return Promise.resolve({ messages: {} });
// };

const i18nData = {
  messages: {
    de: require("./locales/de.js").messages,
    en: require("./locales/en.js").messages
  },
  locale: "de",
  locales: ["de-DE", "en-EN"]
  //  missing: `🚨Translation is missing!`
};

export const i18n = setupI18n(i18nData);

export const getLanguageFromPath = (pathname: string) => {
  return pathname && pathname.startsWith("/en") ? "en" : "de";
};

// @ts-ignore
// i18n.willActivate(loadCatalog);
i18n.activate(siteMetadata.i18n.defaultLanguage);
i18n.loadLocaleData("de", { plurals: i18nData.messages.de })


export const supportedLocale = (locale: Locale) => !!locales[locale];
