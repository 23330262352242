/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import { Wrapper } from "./src/components/Wrapper";

export const wrapRootElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>;
};

/*
export const onInitialClientRender = () => {
  window.addEventListener(
    "popstate",
    () => (window.location.href = window.location.href)
  );
};
*/
