export const smoothScrollToTop = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  if (typeof window !== "undefined") {
    event.preventDefault();

    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  }
};
