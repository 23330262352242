import { colors } from "./colors";
import {
  baseFont,
  baseFontSize,
  baseLineHeight,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  fonts
} from "./typo";

/*
const space = [0, 4, 8, 16, 32, 64, 128];

// animation duration
export const duration = {
  fast: `150ms`,
  normal: `300ms`,
  slow: `450ms`,
  slowest: `600ms`
};

// animation easing curves
const easeInOut = "cubic-bezier(0.5, 0, 0.25, 1)";
const easeOut = "cubic-bezier(0, 0, 0.25, 1)";
const easeIn = "cubic-bezier(0.5, 0, 1, 1)";

const timingFunctions = {
  easeInOut,
  easeOut,
  easeIn
};

// animation delay
const transitionDelays = {
  small: `60ms`,
  medium: `160ms`,
  large: `260ms`,
  xLarge: `360ms`
};

const shadows = [
  `0`,
  `0px 10px 20px rgba(0, 0, 0, 0.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 2px 8px 0 rgba(0,0,0,.3)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 4px 16px 0 rgba(0,0,0,.5)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 8px 32px 0 rgba(0,0,0,.7)`
];
*/
const zIndex = {
  normal: 0,
  low: 10,
  mid: 100,
  high: 1000
};

const textStyles = {
  copy: {
    fontSize: /* ["17px", "20px", "22px", */ "24px" /* ], */,
    fontWeight: fontWeights.normal,
    lineHeight: 1.5,
    letterSpacings: 1.5,
    fontFamily: fonts.nittiGroteskSemiLight,
    // color: colors.black,
    color: "red",
    mb: "1rem"
  },
  copyLink: {
    fontSize: [1.2],
    fontWeight: [fontWeights.normal],
    fontFamily: fonts.vario,
    color: colors.pink
  },
  big: {},
  wahn: {
    fontFamily: fonts.vario
  },
  icon: {
    fontFamily: fonts.vario
  }
};

const listStyles = {
  blitz: {
    li: {
      color: colors.blue,
      "&::before": {
        content: "> ",
        fontFamily: fonts.vario,
        textTransform: "uppercase",
        color: colors.pink,
        verticalAlign: "baseline"
      }
    }
  }
};

const maxWidth = 1440;

export const theme = {
  baseFont,
  baseFontSize,
  baseLineHeight,
  maxWidth,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  textStyles,
  listStyles,
  colors,
  zIndex,
  maxContainerWidth: maxWidth
};
