import { createGlobalStyle } from "styled-components";
import { media } from "./media";
import { colors } from "./colors";
import { baseFont, baseFontSize, fontWeights, fonts } from "./typo";

export const GlobalStyles = createGlobalStyle<{
  bgPaper?: null | "dot" | "graph" | "square";
}>`
  @keyframes wahnsinn {
    0%   { font-variation-settings: "WAHN" 0, "wght" 520; }
    49%   { font-variation-settings: "WAHN" 0, "wght" 520; }
    50%   { font-variation-settings: "WAHN" 100, "wght" 520; }
    100% { font-variation-settings: "WAHN" 100, "wght" 520; }
  }

  * {
    box-sizing: border-box;
  }

  :root {
    font-family: ${baseFont};
    font-size: ${baseFontSize}px;
  }

  body {
    color: ${colors.black};
    margin: 0;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    ${media.tablet} {
        background-repeat: repeat;
        background-image: ${({ bgPaper }) =>
          bgPaper
            ? `url("${require("./assets/paper_" + bgPaper + ".svg")}")`
            : "none"};

          
      }

      ${media.landscape} {
        background-image: none;
      }
    }
  }



  #___gatsby {
    overflow:  hidden;

    > div {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      min-height: 100vh;
      width: 100vw;
      overflow-x: hidden;
    }
  }

  main {
    flex: 1;
    margin: 0;
    overflow: visible;
  }

  footer {
    flex: 0;
  }

  img {
    outline: 0;
    max-width: 100%;
    vertical-align: middle;
  }

  p {
    font-family: ${fonts.nittiGroteskSemiLight};
    font-weight: ${fontWeights.semiLight};
    line-height: 1.3;
    font-size: 1.333rem;
    
    color: ${colors.black};
    
    margin: 0 0 1em 0;

    ${media.tablet} {
      font-size: 1.46666rem;
    }

    ${media.laptop} {
      font-size: 1.6rem
    }
  }

  a {
    text-decoration: none;    
    cursor: pointer;

    color: inherit;

    &:hover {
      color: ${colors.pink};
    }
  }

  ol, ul {
    padding: 0;
    margin: 0 0 1rem 0;
  }

  li {
    list-style-type: none;
    margin-bottom: 0;

    h1, h2, h3, h4, h5, h6 {
      overflow: visible !important;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    flex: 0 1 50%;
    text-align: right;
  }

  dd {
    flex: 0 1 50%;
    font-weight: bold;
    text-align: right;
    margin-left: auto;
  }

  figure {
    margin: 0;
  }

  input {
    border-radius: 0;

    border: 0;
    border-bottom: 1px solid ${colors.black};

    font-size: 1rem;
    font-family: ${fonts.vario};
    text-transform: uppercase;
    outline: 0;

    padding: 0 0.75rem;
    margin: 0;
  }

  button {
    appearance: none;
    border: 0;
    font-family: ${fonts.nittiGroteskBold};
    font-size: 18px;
    font-weight: ${fontWeights.bold};

    background-color: ${colors.black};
    color: ${colors.white};

    cursor: pointer;

    margin-left: .3rem;

    &:hover {
      background-color: ${colors.pink};
    }
  }

  /*
  strong {
    font-weight: ${fontWeights.bold};
    font-family: ${fonts.nittiGroteskBold};
  }
  */

  h1, h2, h3, h4, h5, h6 {
    font-family: ${fonts.nittiGroteskHeavy};
    font-weight: ${fontWeights.heavy};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    width: 100%;
    line-height: 1.15;
    letter-spacing: -0.5px;

    ${media.tablet} {
      letter-spacing: -1px;
    }
  }

  h1 {
    font-size: 2.5rem;
    margin: 0 0 2rem 0;

    ${media.tablet} {
      font-size: 4rem; 
    }
  }

  h2 {
    font-size: 2.2rem;
    margin: 0 0 1.5rem 0;

    ${media.tablet} {
      font-size: 3.3rem; 
    }

  }

  h3 {
    font-size: 1.7333rem;
    margin: 0 0 2.5rem 0;

    ${media.tablet} {
      font-size: 2rem; 
    }

    ${media.laptop} {
      font-size: 2.7rem; 
    }

    ${media.desktop} {
      font-size: 3.2rem; 
    }
  }

  h4 {
    font-size: 1.5rem;
    font-weight: ${fontWeights.bold};
    font-family: ${fonts.nittiGroteskBold};
    letter-spacing: 0px;
    margin: 0 0 2rem 0;
  }

  h5 {
    font-size: 1.25rem;
    font-weight: ${fontWeights.bold};
    font-family: ${fonts.nittiGroteskBold};
    letter-spacing: 0px;
    margin: 0 0 1.5rem 0;
  }

  h6 {
    font-size: 1.15rem;
    font-weight: ${fontWeights.bold};
    font-family: ${fonts.nittiGroteskBold};
    letter-spacing: 0px;
    margin: 0 0 1rem 0;
  }
`;
