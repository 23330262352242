// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-awards-tsx": () => import("./../../../src/pages/awards.tsx" /* webpackChunkName: "component---src-pages-awards-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ingolf-tsx": () => import("./../../../src/pages/ingolf.tsx" /* webpackChunkName: "component---src-pages-ingolf-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-news-[slug]-tsx": () => import("./../../../src/pages/news/[slug].tsx" /* webpackChunkName: "component---src-pages-news-[slug]-tsx" */),
  "component---src-pages-presse-und-news-tsx": () => import("./../../../src/pages/presse-und-news.tsx" /* webpackChunkName: "component---src-pages-presse-und-news-tsx" */),
  "component---src-pages-project-tsx": () => import("./../../../src/pages/project.tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-specials-tsx": () => import("./../../../src/pages/specials.tsx" /* webpackChunkName: "component---src-pages-specials-tsx" */),
  "component---src-pages-studio-tsx": () => import("./../../../src/pages/studio.tsx" /* webpackChunkName: "component---src-pages-studio-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-special-template-tsx": () => import("./../../../src/templates/SpecialTemplate.tsx" /* webpackChunkName: "component---src-templates-special-template-tsx" */)
}

