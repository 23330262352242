export interface Breakpoints {
  [name: string]: number;
}

export interface Medias {
  [name: string]: string;
}

export const breakpoints: Breakpoints = {
  mobile: 0,
  landscape: 481,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
  hd: 1920
};

export const media: Medias = {
  smalltables:
    "@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)",
  landscape:
    "@media only screen and (min-width: 400px) and (max-width: 896px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)",
  tablet: "@media screen and (min-width: 768px)",
  laptop: "@media screen and (min-width: 1024px)",
  desktop: "@media screen and (min-width: 1440px)",
  hd: "@media screen and (min-width: 1920px)"
};

/*
Object.keys(breakpoints).reduce(
  (acc: Medias, item) => {
    acc[item] = `@media screen and (min-width: ${breakpoints[item]}px)`;
    return acc;
  },
  {}
);
*/
