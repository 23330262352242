import * as styledComponents from "styled-components";

const { default: styled, css, createGlobalStyle, keyframes } = styledComponents;

export const themed = (key: string) => (props: any) => props.theme[key];

export { styled, css, createGlobalStyle, keyframes };
export * from "styled-system";

export * from "./theme";
export * from "./GlobalStyles";

export * from "./colors";
export * from "./media";
export * from "./typo";

export const angle = (n: number) =>
  `transform: rotate(${n}deg); will-change: transform;`;

export interface AngleProps {
  angle?: number | number[];
}

export default styled;
