import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo
} from "react";
import ReactDOM from "react-dom";
import { navigate } from "../../utils";
import { styled, media } from "../../styles";
import { CallToAction } from "./CallToAction";
import { Model } from "./lib/Model";

// Use a ternary operator to make sure that the document object is defined
const portalRoot =
  typeof document !== `undefined` ? document.getElementById("portal") : null;

const handleSkullClick = (id: string) => {
  switch (id) {
    case "fragment-studio": {
      navigate(`/studio/`);
      break;
    }

    case "fragment-work": {
      navigate(`/work/`);
      break;
    }

    case "fragment-specials": {
      navigate(`/specials/`);
      break;
    }

    case "fragment-music": {
      navigate(`/music/`);
      break;
    }

    case "fragment-about": {
      navigate(`/#about`);
      break;
    }

    case "fragment-team": {
      navigate(`/team/`);
      break;
    }
  }
};

export const SkullContext = createContext<
  [
    string | undefined,
    (from: string) => void,
    boolean,
    (isVisible: boolean) => void
  ]
>([undefined, () => {}, false, () => {}]);

export const SkullContextProvider: React.FC<{}> = ({ children }) => {
  const [enterFrom, setEnterFrom] = useState<string | undefined>(undefined);
  const [isSkullVisible, setSkullVisible] = useState(false);

  const initialValue: any = [
    enterFrom,
    setEnterFrom,
    isSkullVisible,
    setSkullVisible
  ];

  return (
    <SkullContext.Provider value={initialValue}>
      {children}
    </SkullContext.Provider>
  );
};

const SkullContainer: React.FC<{}> = () => {
  const [enterFrom, setEnterFrom, isSkullVisible, setSkullVisible] = useContext(
    SkullContext
  );
  const [isSkullReady, setSkullReady] = useState(false);

  const [callToActionIsVisible, setCallToActionIsVisible] = useState(true);

  const setCallToActionInvisible = useCallback(() => {
    if (callToActionIsVisible) {
      setCallToActionIsVisible(false);
    }
  }, [callToActionIsVisible]);

  const onSkullReady = useCallback(() => {
    setSkullReady(true);
  }, []);

  const model = useMemo(
    () => new Model(enterFrom, onSkullReady, handleSkullClick),
    []
  );

  useEffect(() => {
    model.setEnterFrom(enterFrom);
  }, [enterFrom]);

  useEffect(() => {
    if (isSkullVisible) {
      model.start();
    } else {
      model.stop();
    }

    return () => {
      // model.stop();
    };
  }, [isSkullVisible]);

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        height: "100vh",
        width: "100vw",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        display: isSkullVisible ? "flex" : "none"
      }}
    >
      {isSkullReady && isSkullVisible && (
        <CallToAction
          isVisible={callToActionIsVisible}
          onClick={setCallToActionInvisible}
        />
      )}

      <StyledSkull id="skull" onClick={setCallToActionInvisible} />
    </div>
  );
};

const StyledSkull = styled.div`
  position: relative;

  height: 100vh;
  width: 150vh;
  max-width: 100vw;
  max-height: 140vw;
  z-index: 10;
  top: -10vh;

  ${media.landscape} {
    height: 100vh;
    width: 100vw;
    top: 0;
  }
`;

class SkullPortal extends React.Component<{}, {}> {
  public el: any;

  constructor(props: any, ctx: any) {
    super(props, ctx);

    // Use a ternary operator to make sure that the document object is defined
    this.el =
      typeof document !== `undefined` ? document.createElement("div") : null;
  }

  public componentDidMount = () => {
    if (portalRoot) {
      portalRoot.appendChild(this.el);
    }
  };

  public componentWillUnmount = () => {
    if (portalRoot) {
      portalRoot.removeChild(this.el);
    }
  };

  public render() {
    // Check that this.el is not null before using ReactDOM.createPortal
    return this.el ? ReactDOM.createPortal(<SkullContainer />, this.el) : null;
  }
}

export const Skull: React.FC<{}> = () => <SkullPortal />;
