const config = {
  name: "German Wahnsinn",
  shortName: "germwahnsinn",
  title: "German Wahnsinn | Tonstudio Hamburg Musik - Film - Werbung",
  image: "https://germanwahnsinn.de/gw_social.png",
  siteUrl: "https://germanwahnsinn.de",
  description:
    "Tonstudio Hamburg für Musik, Film, Werbung und Hörspiele bietet professionelle Tonaufnahmen. In unseren Tonstudios versorgen wir Fernsehproduktionen, Musik…",
  keywords:
    "tonstudio hamburg, tonstudio, tonstudios, musiker, film, werbung, german wahnsinn",
  color: "#ff0064",
  social: {
    facebook: "https://www.facebook.com/GermanWahnsinn/",
    twitter: "https://twitter.com/GermanWahnsinn",
    instagram: "https://www.instagram.com/germanwahnsinn/",
    spotify: "https://open.spotify.com/artist/2NnaatfEYKcqBk4Qr5OtvP",
    vimeo: "https://vimeo.com/germanwahnsinn",
    youtube: "https://www.youtube.com/channel/UCyFB2iYYgABcdcrFHi4WSIA"
  },
  copyright: "Copyright © 2019. German Wahnsinn", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#ffffff", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ff0064", // Used for setting manifest background color.
  i18n: {
    languages: ["de", "en"],
    defaultLanguage: "de"
  },
  google: {
    analyticsId: "UA-128298269-1",
    verification: ""
  }
};

/*
const youtube = {
  channelId: "UCyFB2iYYgABcdcrFHi4WSIA",
  apiKey: "AIzaSyB-yfT_9ui2U7Q0e6MAbtrkjaTtpwv2TRo"
};
*/

module.exports = {
  siteMetadata: {
    ...config
  },

  plugins: [
    {
      resolve: "gatsby-plugin-htaccess",
      options: {
        RewriteBase: "/",
        https: true,
        www: false,
        SymLinksIfOwnerMatch: true,
        host: "germanwahnsinn.de",
        redirect: [],
        custom: `
            # This is a custom rule!
            # This is a another custom rule!
        `
      }
    },

    {
      resolve: "gatsby-plugin-react-helmet"
    },

    {
      resolve: "gatsby-transformer-sharp"
    },

    {
      resolve: "gatsby-plugin-sharp"
    },

    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /\.inline\.svg$/
        }
      }
    },

    {
      resolve: "gatsby-plugin-manifest",
      options: {
        name: config.name,
        short_name: config.shortName,
        start_url: "/",
        background_color: config.backgroundColor,
        theme_color: config.themeColor,
        display: "minimal-ui",
        icon: "src/assets/favicon.svg",
        icons: [
          {
            src: "favicons/icon-48x48.png",
            sizes: "48x48",
            type: "image/png"
          },
          {
            src: "favicons/icon-72x72.png",
            sizes: "72x72",
            type: "image/png"
          },
          {
            src: "favicons/icon-96x96.png",
            sizes: "96x96",
            type: "image/png"
          },
          {
            src: "favicons/icon-144x144.png",
            sizes: "144x144",
            type: "image/png"
          },
          {
            src: "favicons/icon-192x192.png",
            sizes: "192x192",
            type: "image/png"
          },
          {
            src: "favicons/icon-256x256.png",
            sizes: "256x256",
            type: "image/png"
          },
          {
            src: "favicons/icon-384x384.png",
            sizes: "384x384",
            type: "image/png"
          },
          {
            src: "favicons/icon-512x512.png",
            sizes: "512x512",
            type: "image/png"
          }
        ]
      }
    },

    {
      resolve: "gatsby-plugin-styled-components"
    },

    {
      resolve: "gatsby-plugin-typescript"
    },

    {
      resolve: `gatsby-plugin-portal`,
      options: {
        key: "portal",
        id: "portal"
      }
    },

    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "images",
        path: `${__dirname}/src/assets`
      }
    },

    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/src/data/`
      }
    },

    {
      resolve: "gatsby-transformer-json"
    },

    {
      resolve: "gatsby-plugin-sitemap"
    },

    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: config.google.analyticsId
      }
    }
  ]
};
