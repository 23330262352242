import React from "react";
import { I18nProvider } from "@lingui/react";
import { ThemeProvider } from "styled-components";
import { i18n } from "../../i18n";
import { WorkContextProvider } from "../../views/WorkPage/WorkContext";
import { Skull, SkullContextProvider } from "../Skull";
import "../../styles/css/fonts.css";

export const Wrapper: React.FC<{}> = ({ children }) => {
  const theme = {
    breakpoints: [
      "25em",
      "44.46667em",
      "51.2em",
      "68.26667em",
      "96em",
      "128em",
      "172em"
    ]
  };

  return (
    <ThemeProvider theme={theme}>
      <I18nProvider i18n={i18n}>
        <WorkContextProvider>
          <SkullContextProvider>
            {children}
            <Skull />
          </SkullContextProvider>
        </WorkContextProvider>
      </I18nProvider>
    </ThemeProvider>
  );
};
