import { navigate as gatsbyNavigate } from "gatsby";

export const navigate = (to: string) => {
  const prefix =
    typeof window !== "undefined" &&
    window.location &&
    window.location.pathname.startsWith("/en")
      ? "/en"
      : "";

  const nextTo = to.replace("/en", "");

  if (!nextTo.startsWith("/")) {
    throw new Error("An internal link has to start with '/'");
  }

  gatsbyNavigate(`${prefix}${nextTo}`);
};
