import { useState, useRef } from "react";

interface ErrorEvent<T extends EventTarget> extends Event {
  readonly target: T;
}

export function useImagePreloader() {
  let totalAmountOfImages = useRef(0);
  let imagesLoaded = useRef(0);

  const [progress, setProgress] = useState(0);
  const [pending, setPending] = useState(true);

  function updateProgress() {
    // console.log(imagesLoaded.current);
    imagesLoaded.current = imagesLoaded.current + 1;
    setProgress((imagesLoaded.current / totalAmountOfImages.current) * 100);
    if (imagesLoaded.current === totalAmountOfImages.current) setPending(false);
  }

  function loadImage(url: string): Promise<HTMLImageElement | Error> {
    return new Promise((resolve, reject) => {
      const image: HTMLImageElement = new Image();
      image.addEventListener("load", () => {
        updateProgress();
        resolve(image);
      });
      image.addEventListener("error", function(
        this: HTMLImageElement,
        event: ErrorEvent<any>
      ) {
        const { target } = event;
        const error = new Error(
          `${event.type}: Loading image:\n ${
            target.attributes.getNamedItem("src").baseURI
          }`
        );
        updateProgress();
        reject(error);
      });
      image.src = url;
    });
  }

  const preloadImages = (images: string[]) => {
    totalAmountOfImages.current = images.length;
    return Promise.allSettled(images.map(image => loadImage(image)));
  };

  return { preloadImages, pending, progress };
}
